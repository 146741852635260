import { graphql, useMutation } from 'react-relay';

const mutation = graphql`
  mutation PortfolioUserMemberUpdateRoleMutation($uuid: ID!, $input: PortfolioUserMemberUpdateRoleInput!) {
    portfolioUserMemberUpdateRole(uuid: $uuid, input: $input) {
      uuid
      role
    }
  }
`;

/**
 * Hook for updating a portfolio user member role.
 * Using `useMutation` hook as recommended by [Relay docs](https://relay.dev/docs/api-reference/use-mutation/)
 * as an improvement over calling `commitMutation` directly.
 * @returns {Function} A function to trigger the portfolio user member update role mutation
 */
function usePortfolioUserMemberUpdateRoleMutation() {
  const [commit] = useMutation(mutation);

  const portfolioUserMemberUpdateRole = (uuid, input, onCompleted, onError) => {
    commit({
      variables: { uuid, input },
      onCompleted,
      onError,
    });
  };

  return portfolioUserMemberUpdateRole;
}

export default usePortfolioUserMemberUpdateRoleMutation;
