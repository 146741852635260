import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button, Card, CardBody, CardFooter, CardHeader,
  FormGroup, FormText, Label, Form as ReactstrapForm,
} from 'reactstrap';

import Typeahead from 'src/components/Form/Input/Typeahead';
import FlashesStore from 'src/stores/FlashesStore';
import { PORTFOLIO_USER_ROLES, PORTFOLIO_USER_ROLE_OWNER } from 'src/util/constants';
import { getPortfolioUserRoleLabel } from 'src/util/i18n/helpers';

/**
 * @param {object} props
 * @param {Function} props.handleEdit function handling the mutation.
 * @param {boolean} props.portfolioHasOwner whether the portfolio has an owner.
 * @param {boolean} props.processing whether the mutation is being processed.
 * @param {string} props.role current user role.
 * @returns {React.ReactComponentElement} - Form component.
 */
function Form({
  handleEdit,
  portfolioHasOwner,
  processing,
  role,
}) {
  const [selectedRole, setSelectedRole] = useState({ label: '', roleValue: '' });
  const [roleValid, setRoleValid] = useState(null);

  const intl = useIntl();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (processing) {
      const msgInfo = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_edit.form.state.invalid_still_propcessing', defaultMessage: 'We are still processing your request...' });
      FlashesStore.flash(FlashesStore.INFO, msgInfo);
      return;
    }

    const input = {
      newUserRole: selectedRole.roleValue,
    };

    handleEdit(input);
  };

  const handleRoleChange = (selected) => {
    if (selected.length > 0) {
      const [roleValue] = selected;
      setSelectedRole(roleValue);
      setRoleValid(true);
    } else {
      setRoleValid(false);
    }
  };

  // removing portfolio owner from the roles options if the portfolio already has an owner
  const rolesWithoutOwner = PORTFOLIO_USER_ROLES.filter(
    (userRole) => userRole !== PORTFOLIO_USER_ROLE_OWNER,
  );
  const rolesOptions = portfolioHasOwner ? rolesWithoutOwner : PORTFOLIO_USER_ROLES;
  const roles = rolesOptions.map(
    (userRole) => ({
      label: getPortfolioUserRoleLabel(intl, userRole),
      roleValue: userRole,
      disabled: userRole === role,
    }),
  );
  const initialUserMemberRole = getPortfolioUserRoleLabel(intl, role);

  return (
    <ReactstrapForm onSubmit={handleSubmit} disabled={processing}>
      <Card>
        <CardHeader tag="h2">
          {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_edit.form.heading.label', defaultMessage: "Edit portfolio user member's role" })}
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="role">{intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_edit.form.role.label', defaultMessage: 'Role' })}</Label>
            <Typeahead
              clearButton
              defaultInputValue={initialUserMemberRole}
              disabled={processing}
              id="role"
              isValid={roleValid}
              isInvalid={roleValid !== null && !roleValid}
              onChange={handleRoleChange}
              options={roles}
              style={{ marginBottom: '0.25rem' }}
            />
            {roleValid != null && !roleValid && (
            <FormText>
              {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_edit.form.role.feedback.label', defaultMessage: 'Role cannot be empty' })}
            </FormText>
            )}
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Button disabled={processing || !selectedRole.roleValue || !roleValid}>{intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_edit.form.submit.label', defaultMessage: 'Update' })}</Button>
        </CardFooter>
      </Card>
    </ReactstrapForm>
  );
}

export default Form;

Form.propTypes = {
  handleEdit: PropTypes.func.isRequired,
  portfolioHasOwner: PropTypes.bool.isRequired,
  processing: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
};
