import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, usePaginationFragment } from 'react-relay';
import {
  Card, CardFooter, CardHeader, Table,
} from 'reactstrap';

import Address from 'src/components/Address';
import BadgeActive from 'src/components/Badge/BadgeActive';
import LoadMoreButton from 'src/components/LoadMoreButton';
import UUID from 'src/components/UUID';

const AdminPortfolioPropertyMembersFragment = graphql`
  fragment AdminPortfolioPropertyMembers_portfolio on Portfolio
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  )
  @refetchable(queryName: "PortfolioPropertyMembersPaginationQuery") {
    uuid
    propertyMembers(after: $cursor, first: $count)
      @connection(key: "PortfolioPropertyMembers_propertyMembers") {
      edges {
        node {
          # Compose the data dependencies of child components
          # by spreading their fragments:
          uuid
          externalIdentifier
          active { start finish }
          property {
            uuid
            externalIdentifier
            title
            active { start finish }
            address {
              line1 line2 city state postcode country
            }
          }
        }
      }
    }
  }
`;

/**
 * Renders an alert component to inform the user that there is
 * no inactive property in the portfolio.
 * @returns {React.ReactElement} The alert component.
 */
export function noPropertiesMsg() {
  return (
    <FormattedMessage
      id="admin.admin_portfolio.admin_portfolio_show.admin_portfolio_property_members.no_property_member.message"
      defaultMessage="<strong>No property memberships found in this portfolio.</strong> Unfortunately we do not currently have any property memberships for this portfolio. If you believe that this is an error please make use of our support site to raise an issue."
      values={{
        strong: (chunks) => <strong>{chunks}</strong>,
      }}
    />
  );
}

/**
 * Returns the PortfolioProperyMembersCard component
 * @param {object} props
 * @param {object} props.portfolio
 * @returns {React.ReactComponentElement} - PortfolioProperyMembersCard component
 */
function AdminPortfolioPropertyMembers(props) {
  if (!props) {
    return null;
  }

  const { portfolio } = props || {};
  const {
    data, loadNext, hasNext, isLoadingNext,
  } = usePaginationFragment(AdminPortfolioPropertyMembersFragment, portfolio);

  const propertyMembers = data.propertyMembers?.edges.map((edge) => edge.node);

  const intl = useIntl();

  /**
   * Loads the next set of portfolio property members when the user hits the load more button.
   * @returns {void}
   */
  const loadMore = () => {
    if (!hasNext || isLoadingNext) {
      return;
    }

    loadNext(
      50,
      { onComplete: (error) => { console.log({ error }); } },
    );
  };

  const copyLoadMore = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_property_members.load_more_button.state_load_more.label', defaultMessage: 'Load more...' });
  const copyNoMore = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_property_members.load_more_button.state_no_more.label', defaultMessage: 'No more portfolio property members to load' });

  return (
    <Card className="mb-4 w-100">
      <CardHeader>
        {intl.formatMessage({
          id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_property_members.card_header.portfolio_property_members.label',
          defaultMessage: 'Property Members',
        })}
      </CardHeader>
      <Table responsive>
        <thead>
          <tr>
            <th scope="col">
              {intl.formatMessage({
                id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_property_members.card_body.head.title.label',
                defaultMessage: 'Title',
              })}
            </th>
            <th scope="col">
              {intl.formatMessage({
                id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_property_members.card_body.head.address.label',
                defaultMessage: 'Address',
              })}
            </th>
            <th scope="col">
              <abbr title={intl.formatMessage({
                id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_property_members.card_body.head.identifier.abbr.title',
                defaultMessage: 'Identifier',
              })}
              >
                {intl.formatMessage({
                  id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_property_members.card_body.head.id.label',
                  defaultMessage: 'ID',
                })}
              </abbr>
            </th>
            <th scope="col">
              <abbr title={intl.formatMessage({
                id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_property_members.card_body.head.external_identifier.abbr.title',
                defaultMessage: 'External identifier',
              })}
              >
                {intl.formatMessage({
                  id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_property_members.card_body.head.external_identifier.label',
                  defaultMessage: 'Ext ID',
                })}
              </abbr>
            </th>
            <th scope="col">
              {intl.formatMessage({
                id: 'admin.admin_portfolio.admin_portfolio_show.admin_portfolio_property_members.card_body.head.active.label',
                defaultMessage: 'Active',
              })}
            </th>
          </tr>
        </thead>
        <tbody>
          {propertyMembers.length === 0 && (
            <tr>
              <td colSpan={5}>
                {noPropertiesMsg()}
              </td>
            </tr>
          )}
          {
            propertyMembers.map((member) => {
              const {
                active, externalIdentifier, uuid, property,
              } = member || {};
              const { address, title } = property || {};
              const { start, finish } = active || {};
              const addr = address ? new Address(address) : '';
              const finalAddress = addr ? addr.simpleString() : 'PROPERTY ADDRESS';
              const redirectLink = `/admin/portfolio-property-members/${uuid}`;
              return (
                <tr key={uuid}>
                  <th scope="row" colSpan={2}>
                    <Link to={redirectLink}>
                      {title}
                    </Link>
                    <div>{finalAddress}</div>
                  </th>
                  <th className="fw-normal" scope="row" colSpan={2}>
                    <UUID uuid={uuid} />
                    <br />
                    {externalIdentifier}
                  </th>
                  <td>
                    <BadgeActive start={start} finish={finish} />
                    <br />
                    {start && DateTime.fromSeconds(start)
                      .toLocaleString(DateTime.DATETIME_FULL)}
                    -
                    {finish && DateTime.fromSeconds(finish)
                      .toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </Table>
      <CardFooter>
        <LoadMoreButton
          copyLoadMore={copyLoadMore}
          copyNoMore={copyNoMore}
          hasNext={hasNext}
          onClick={loadMore}
        />
      </CardFooter>
    </Card>
  );
}

export default AdminPortfolioPropertyMembers;

AdminPortfolioPropertyMembers.propTypes = {
  // note: known bug https://github.com/jsx-eslint/eslint-plugin-react/issues/1002#issuecomment-320160686
  // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
  portfolio: PropTypes.object.isRequired,
};
