import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button, Card, CardBody, CardFooter, CardHeader,
  FormFeedback, FormGroup, FormText, Input, Label, Form as ReactstrapForm,
} from 'reactstrap';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - Form component
 */
function Form(props) {
  if (!props) {
    return null;
  }
  const { handleEditPortfolio, processing, portfolioTitle } = props;
  const [title, setTitle] = useState(portfolioTitle);
  const [invalidTitle, setInvalidTitle] = useState(false);
  const intl = useIntl();

  const invalidTitleMsg = (title && title === portfolioTitle)
    ? intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_edit.form.same_as_old_title_invalid_title.feedback', defaultMessage: 'The new title cannot be the same as the old one' })
    : intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_edit.form.empty_title_invalid_title.feedback', defaultMessage: 'Please enter a title for the portfolio' });

  const handleTitleChange = (event) => {
    const { value: inputTitle } = event.target;
    setTitle(inputTitle);
    setInvalidTitle(!inputTitle);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!(title?.trim()) || title === portfolioTitle) {
      setInvalidTitle(true);
      return;
    }

    handleEditPortfolio(title);
  };

  return (
    <ReactstrapForm onSubmit={handleSubmit} disabled={processing}>
      <Card>
        <CardHeader>
          <h2>{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_edit.form.heading', defaultMessage: 'General Settings' })}</h2>
          <span>
            {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_edit.form.heading.description', defaultMessage: 'Here you can amend the title of your portfolio' })}
          </span>
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="title">{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_edit.form.title.label', defaultMessage: 'Title' })}</Label>
            <Input disabled={processing} type="text" name="title" id="title" onChange={handleTitleChange} value={title} invalid={invalidTitle} />
            <FormText color="muted">
              {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_edit.form.title.description', defaultMessage: 'A memorable title to refer to the portfolio by.' })}
            </FormText>
            <FormFeedback>{invalidTitleMsg}</FormFeedback>
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Button disabled={processing || invalidTitle || title === portfolioTitle} type="submit">{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_edit.form.submit.label', defaultMessage: 'Update portfolio settings ' })}</Button>
        </CardFooter>
      </Card>
    </ReactstrapForm>
  );
}
export default Form;

Form.propTypes = {
  handleEditPortfolio: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  portfolioTitle: PropTypes.string.isRequired,
};
