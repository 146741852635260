import Link from 'found/Link';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button, Card, CardBody, CardFooter, CardHeader,
  FormGroup, Input, Form as ReactstrapForm,
} from 'reactstrap';

/**
 * @param {object} props
 * @param {Function} props.handleSubmit function handling the mutation.
 * @param {boolean} props.processing whether the mutation is being processed.
 * @param {string} props.portfolioTitle - portfolio title.
 * @param {string} props.portfolioUuid - portfolio uuid.
 * @returns {React.ReactComponentElement} - Form component.
 */
function Form({
  handleSubmit,
  processing,
  portfolioTitle,
  portfolioUuid,
}) {
  const [retireValue, setRetireValue] = useState('');

  const intl = useIntl();

  const updateRetireValue = (e) => {
    setRetireValue(e?.target?.value);
  };
  const formRetireLabel = intl.formatMessage({
    id: 'admin.admin_portfolio.admin_portfolio_retire.form.retire.label',
    defaultMessage: 'retire',
  });

  return (
    <ReactstrapForm onSubmit={handleSubmit} disabled={processing} data-testid="form">
      <Card>
        <CardHeader tag="h2">
          {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_retire.form.heading.label', defaultMessage: 'Retire portfolio' })}
        </CardHeader>
        <CardBody>
          <p className="mb-2">
            <FormattedMessage
              id="admin.admin_portfolio.admin_portfolio_retire.form.confirmation.message"
              defaultMessage="Are you sure you want to retire {portfolioTitle} ?"
              values={{
                portfolioTitle: (
                  <Link className="d-inline" to={`/admin/portfolios/${portfolioUuid}`}>
                    {portfolioTitle}
                  </Link>
                ),
              }}
            />
          </p>
          <p className="mb-2">{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_retire.form.disclaimer.message', defaultMessage: 'Retiring a portfolio ceases it being accessible for management.' })}</p>
          <FormGroup className="d-flex align-items-center">
            <p className="d-flex align-items-center mb-0 me-2">
              <FormattedMessage
                id="admin.admin_portfolio.admin_portfolio_retire.form.input_field_confirmation_retire.message"
                defaultMessage="Please type {retire} to confirm"
                values={{
                  retire: (
                    <strong className="mx-1">{formRetireLabel}</strong>
                  ),
                }}
              />
            </p>
            <Input style={{ width: '15%' }} type="text" value={retireValue} onChange={(e) => updateRetireValue(e)} />
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Button className="btn btn-danger" disabled={!(retireValue === formRetireLabel || processing)}>{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_retire.form.submit.label', defaultMessage: 'Retire {portfolioTitle}' }, { portfolioTitle })}</Button>
        </CardFooter>
      </Card>
    </ReactstrapForm>
  );
}

export default Form;

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  portfolioTitle: PropTypes.string.isRequired,
  portfolioUuid: PropTypes.string.isRequired,
};
