import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button, Card, CardBody, CardFooter, CardHeader,
  FormFeedback, FormGroup, FormText, Input, Label, Form as ReactstrapForm,
} from 'reactstrap';
import isEmail from 'validator/es/lib/isEmail';

import Typeahead from 'src/components/Form/Input/Typeahead';
import { activeStartStringToDateTime, validateExternalIdentifierInput } from 'src/components/Form/Input/utils';
import FlashesStore from 'src/stores/FlashesStore';
import { PORTFOLIO_USER_ROLES, PORTFOLIO_USER_ROLE_OWNER } from 'src/util/constants';
import { getPortfolioUserRoleLabel } from 'src/util/i18n/helpers';

/**
 * @param {object} props
 * @param {Function} props.handleInvite function handling the mutation.
 * @param {boolean} props.processing whether the mutation is being processed.
 * @param {boolean} props.portfolioHasOwner whether the portfolio has an owner.
 * @param {DateTime | null} props.minStart The minimum active start date which the user can select
 * @param {string} props.timezone The partner's timezone
 * @returns {React.ReactComponentElement} Create portfolio user member form component.
 */
function Form({
  handleInvite,
  processing,
  portfolioHasOwner,
  minStart,
  timezone,
}) {
  const minStartString = minStart ? minStart.toUTC().toISO() : null;
  const flooredCurrentTimeInSeconds = Math.floor(DateTime.now().toSeconds());
  const currentTimeString = DateTime.fromSeconds(flooredCurrentTimeInSeconds).toUTC().toISO();

  const [activeStart, setActiveStart] = useState(currentTimeString);
  const [activeStartInvalid, setActiveStartInvalid] = useState(false);
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(null);
  const [externalIdentifier, setExternalIdentifier] = useState('');
  const [externalIdentifierInvalid, setExternalIdentifierInvalid] = useState(false);
  const [externalIdentifierFeedback, setExternalIdentifierFeedback] = useState(null);
  const [role, setRole] = useState({ label: '', roleValue: '' });
  const [roleValid, setRoleValid] = useState(null);

  const intl = useIntl();
  const maximumLength = 1024;

  const errorMsgs = {
    MAXIMUM_LIMIT_EXCEEDED: intl.formatMessage({
      id: 'admin.admin_portfolio.admin_portfolio_create.form.form.external_identifier.invalid_external_identifier.max_length_exceeded.feedback',
      defaultMessage: 'External identifier cannot have more than {maximumLength} characters',
    }, { maximumLength }),
    HAS_LEADING_SPACE: intl.formatMessage({
      id: 'admin.admin_portfolio.admin_portfolio_create.form.form.external_identifier.invalid_external_identifier_has_leading_space.feedback',
      defaultMessage: 'External identifier cannot have a leading space',
    }),
    HAS_TRAILING_SPACE: intl.formatMessage({
      id: 'admin.admin_portfolio.admin_portfolio_create.form.form.external_identifier.invalid_external_identifier.has_trailing_space.feedback',
      defaultMessage: 'External identifier cannot have a trailing space',
    }),
  };

  const validateActiveStart = (activeStartString) => {
    const ts = activeStartStringToDateTime(activeStartString, timezone);
    if (!ts) {
      setActiveStartInvalid(true);
      return;
    }
    if (ts < minStart || ts > DateTime.now()) {
      setActiveStartInvalid(true);
      return;
    }
    setActiveStartInvalid(false);
  };

  const isValid = emailValid !== null && emailValid;

  const validateEmail = (emailString) => {
    if (!isEmail(emailString)) {
      setEmailValid(false);
      return;
    }
    setEmailValid(true);
  };

  const validateExternalIdentifier = (extIdentifierString) => {
    const feedbacks = validateExternalIdentifierInput(
      extIdentifierString,
      maximumLength,
    );

    setExternalIdentifierInvalid(feedbacks?.length > 0);
  };

  const validateForm = () => {
    validateActiveStart(activeStart);
    validateEmail(email);
    validateExternalIdentifier(externalIdentifier);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (processing) {
      const msgInfo = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_user_member_create.form.state.invalid_still_propcessing', defaultMessage: 'We are still processing your request...' });
      FlashesStore.flash(FlashesStore.INFO, msgInfo);
      return;
    }

    if (!isValid) {
      const msgInvalidData = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_user_member_create.form.state.invalid_data', defaultMessage: 'Form data not valid. Please see below.' });
      FlashesStore.flash(FlashesStore.ERROR, msgInvalidData);
      return;
    }

    validateForm();

    if (!emailValid || activeStartInvalid || externalIdentifierInvalid || !roleValid) {
      return;
    }

    const input = {
      userEmail: email,
      userRole: role.roleValue,
      activeStart: activeStart ? DateTime.fromISO(activeStart).toSeconds() : null,
      externalIdentifier: externalIdentifier || null,
    };

    handleInvite(input);
  };

  const handleEmailChange = (event) => {
    const { value: inputEmail } = event.target;
    const inputEmailValid = isEmail(email);
    setEmail(inputEmail);
    setEmailValid(inputEmailValid);
  };

  const handleRoleChange = (selected) => {
    if (selected.length > 0) {
      const [roleValue] = selected;
      setRole(roleValue);
      setRoleValid(true);
    } else {
      setRoleValid(false);
    }
  };

  const handleExtIDChange = (event) => {
    const { value: inputExternalIdentifier } = event.target;
    const feedbacks = validateExternalIdentifierInput(
      inputExternalIdentifier,
      maximumLength,
    );
    const feedbackMsg = feedbacks?.map((item) => errorMsgs[item]);

    setExternalIdentifierInvalid(feedbacks?.length > 0);
    setExternalIdentifier(inputExternalIdentifier);
    setExternalIdentifierFeedback(feedbackMsg);
  };

  // removing portfolio owner from the roles options if the portfolio already has an owner
  const rolesWithoutOwner = PORTFOLIO_USER_ROLES.filter(
    (userRole) => userRole !== PORTFOLIO_USER_ROLE_OWNER,
  );
  const rolesOptions = portfolioHasOwner ? rolesWithoutOwner : PORTFOLIO_USER_ROLES;
  const roles = rolesOptions.map(
    (userRole) => ({ label: getPortfolioUserRoleLabel(intl, userRole), roleValue: userRole }),
  );

  const handleActiveStartBlur = () => {
    if (!activeStart) {
      setActiveStart(currentTimeString);
      setActiveStartInvalid(false);
      return;
    }
    const ts = activeStartStringToDateTime(activeStart, timezone);
    if (!ts) {
      setActiveStartInvalid(true);
      return;
    }

    if (activeStart !== ts.toUTC().toISO()) {
      setActiveStart(ts.toUTC().toISO());
    }
    validateActiveStart(activeStart);

    if (!activeStartInvalid) {
      setActiveStart(ts.toUTC().toISO());
      setActiveStartInvalid(false);
    }
  };

  /**
   * Handles the change event for the active start date/time input field.
   * Validates the input value and sets the `timestampInvalid` state accordingly.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event object.
   * @param {object} event.target - The change event object.
   * @param {string} event.target.value - The active start input value in string format.
   */
  const handleActiveStartChange = (event) => {
    const { value: inputActiveStart } = event.target;
    setActiveStart(inputActiveStart);
    validateActiveStart(inputActiveStart);
  };

  const submissionDisabled = processing || !roleValid || !emailValid
  || externalIdentifierInvalid || activeStartInvalid;
  const invalidTimestampMessage = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.form.active_start.feedback', defaultMessage: 'The start cannot be before the portolio start date {minStart}' }, { minStart: minStartString });

  const activeStartHint = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.form.active_start.hint', defaultMessage: '<rfc3339Link>RFC3339 time format</rfc3339Link> <code>YYYY-MM-DDTHH:MM:SSZ</code> (for example, {minStart})' }, { minStart: minStartString, code: (str) => (<code>{str}</code>), rfc3339Link: (str) => (<a href="https://www.rfc-editor.org/rfc/rfc3339">{str}</a>) });

  return (
    <ReactstrapForm onSubmit={handleSubmit} disabled={processing}>
      <Card>
        <CardHeader tag="h2">
          {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_user_member_create.form.heading.label', defaultMessage: 'Invite a new portfolio user member' })}
        </CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="email">{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_user_member_create.form.email.label', defaultMessage: 'User email' })}</Label>
            <Input type="email" name="email" id="email" value={email} onChange={handleEmailChange} disabled={processing} valid={emailValid} invalid={emailValid !== null && !emailValid} />
            <FormFeedback>{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_user_member_create.form.invalid_email.feedback', defaultMessage: 'Not a valid email' })}</FormFeedback>
            <FormText>
              {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_user_member_create.form.email.hint', defaultMessage: 'This email address will be used by the user to log onto the Enosi platform' })}
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label for="role">{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_user_member_create.form.role.label', defaultMessage: 'Role' })}</Label>
            <Typeahead
              clearButton
              id="role"
              onChange={handleRoleChange}
              disabled={processing}
              options={roles}
              isValid={roleValid}
              isInvalid={roleValid !== null && !roleValid}
              style={{ marginBottom: '0.25rem' }}
            />
            {roleValid != null && !roleValid && (
              <FormText>
                {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_user_member_create.form.role.feedback.label', defaultMessage: 'Role cannot be empty' })}
              </FormText>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="externalIdentifier">{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_user_member_create.form.external_identifier.label', defaultMessage: 'External identifier (optional)' })}</Label>
            <Input type="text" name="external identifier" id="externalIdentifier" value={externalIdentifier} onChange={handleExtIDChange} disabled={processing} invalid={externalIdentifierInvalid} />
            <FormText>{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_user_member_create.form.external_identifier.hint', defaultMessage: 'External identifier can have a maximum of {maximumLength} characters with no leading or trailing spaces' }, { maximumLength })}</FormText>
            {externalIdentifierFeedback?.length > 0
              && externalIdentifierFeedback.map((feedback) => (
                <FormFeedback key={feedback}>{feedback}</FormFeedback>
              ))}
          </FormGroup>
          <FormGroup>
            <Label for="activeStart">
              {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_user_member_create.form.active_start.label', defaultMessage: 'Active start (optional)' })}
            </Label>
            <Input id="activeStart" onChange={handleActiveStartChange} value={activeStart} type="text" name="activeStart" disabled={processing} invalid={activeStartInvalid} onBlur={handleActiveStartBlur} />
            <FormText>{activeStartHint}</FormText>
            <FormFeedback>{invalidTimestampMessage}</FormFeedback>
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Button disabled={submissionDisabled}>{intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_user_member_create.form.submit.label', defaultMessage: 'Invite new portfolio user member' })}</Button>
        </CardFooter>
      </Card>
    </ReactstrapForm>
  );
}

export default Form;

Form.propTypes = {
  handleInvite: PropTypes.func.isRequired,
  minStart: PropTypes.instanceOf(DateTime),
  processing: PropTypes.bool.isRequired,
  portfolioHasOwner: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
};

Form.defaultProps = {
  minStart: null,
};
