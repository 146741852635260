/**
 * @generated SignedSource<<bbc6138d3322de9f47a931b5428c2fee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AdminRetirePortfolioUserMember_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PortfolioUserMember",
        "kind": "LinkedField",
        "name": "portfolioUserMember",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdminRetirePortfolioUserMember_portfolioUserMember"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_AdminRetirePortfolioUserMember_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PortfolioUserMember",
        "kind": "LinkedField",
        "name": "portfolioUserMember",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Portfolio",
            "kind": "LinkedField",
            "name": "portfolio",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "givenName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "familyName",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aad62fe568a45bb0d85cf5cb054eb486",
    "id": null,
    "metadata": {},
    "name": "routes_AdminRetirePortfolioUserMember_Query",
    "operationKind": "query",
    "text": "query routes_AdminRetirePortfolioUserMember_Query(\n  $uuid: ID!\n) {\n  portfolioUserMember(uuid: $uuid) {\n    ...AdminRetirePortfolioUserMember_portfolioUserMember\n    id\n  }\n}\n\nfragment AdminRetirePortfolioUserMember_portfolioUserMember on PortfolioUserMember {\n  uuid\n  portfolio {\n    uuid\n    title\n    id\n  }\n  user {\n    email\n    givenName\n    familyName\n    id\n  }\n}\n"
  }
};
})();

node.hash = "ff97829cfa2ef3a20cbffdec568ca650";

module.exports = node;
