/**
 * @generated SignedSource<<92fc5f236606e68213947426c6e5139c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PortfolioUserMemberUpdateRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PortfolioUserMember",
        "kind": "LinkedField",
        "name": "portfolioUserMemberUpdateRole",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PortfolioUserMemberUpdateRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PortfolioUserMember",
        "kind": "LinkedField",
        "name": "portfolioUserMemberUpdateRole",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "90df7506473ba3a9574e36f4ef31e1c1",
    "id": null,
    "metadata": {},
    "name": "PortfolioUserMemberUpdateRoleMutation",
    "operationKind": "mutation",
    "text": "mutation PortfolioUserMemberUpdateRoleMutation(\n  $uuid: ID!\n  $input: PortfolioUserMemberUpdateRoleInput!\n) {\n  portfolioUserMemberUpdateRole(uuid: $uuid, input: $input) {\n    uuid\n    role\n    id\n  }\n}\n"
  }
};
})();

node.hash = "1d95fe562f391223c863aba2e6bfcce6";

module.exports = node;
