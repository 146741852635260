/**
 * Filters out the portfolio property members from the properties array.
 * @param {Array<object>} properties - An array of property objects.
 * @param {object} propertyMembers - An array of property member objects.
 * @returns {object[]} - An array of property objects without the properties
 * that are alredy part of the portfolio.
 */
const filterProperties = (properties, propertyMembers) => {
  if (!properties) {
    return [];
  }
  if (!propertyMembers) {
    return properties;
  }

  const memberUuids = new Set(propertyMembers.edges.map((member) => member.node.property.uuid));
  const filteredProperties = properties.filter((property) => {
    const { node } = property;
    const { uuid: propertyUuid } = node;
    return !memberUuids.has(propertyUuid);
  });
  return filteredProperties;
};

export default filterProperties;
