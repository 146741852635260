/**
 * @generated SignedSource<<a4fe160d09d258026248950e7bf931ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "portfolioUserMemberId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uuid"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "portfolioUserMemberId",
        "variableName": "portfolioUserMemberId"
      },
      {
        "kind": "Variable",
        "name": "uuid",
        "variableName": "uuid"
      }
    ],
    "concreteType": "PortfolioUserMember",
    "kind": "LinkedField",
    "name": "portfolioRetireUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PortfolioRetireUserMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PortfolioRetireUserMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7533fe3e47e3bcd1e59ea2dc8eaa1ff7",
    "id": null,
    "metadata": {},
    "name": "PortfolioRetireUserMutation",
    "operationKind": "mutation",
    "text": "mutation PortfolioRetireUserMutation(\n  $uuid: ID!\n  $portfolioUserMemberId: ID!\n) {\n  portfolioRetireUser(uuid: $uuid, portfolioUserMemberId: $portfolioUserMemberId) {\n    id\n    uuid\n  }\n}\n"
  }
};
})();

node.hash = "487754d61cd3205095446d81dfe7c196";

module.exports = node;
