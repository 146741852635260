import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { Col, Row } from 'reactstrap';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import username from 'src/util/decorators/username';
import isActive from 'src/util/isActive';

import UserMemberDetailsCard from './UserMemberDetailsCard';
import UserMemberPortfolioCard from './UserMemberPortfolioCard';
import UserMemberUserCard from './UserMemberUserCard';
import AdminPortfolioButtonsToolbar from '../../AdminPortfolio/AdminPortfolioControls/AdminPortfolioButtonsToolbar';

const AdminPortfolioUserMemberShowFragment = graphql`
  fragment AdminPortfolioUserMemberShow_portfolioUserMember on PortfolioUserMember
  {
    uuid
    externalIdentifier
    active { start finish }
    portfolio {
      uuid
      externalIdentifier
      title
      active {
        start
        finish
      }
    }
    user {
      uuid
      externalIdentifier
      email
      givenName
      familyName
      active {
        start
        finish
      }
    }
    role
    createdAt
    updatedAt
  }
`;

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminPortfolioUserMemberShow component
 */
function AdminPortfolioUserMemberShow(props) {
  if (!props) {
    return <Loading />;
  }

  const portfolioUserMember = useFragment(
    AdminPortfolioUserMemberShowFragment,
    props.portfolioUserMember, // eslint-disable-line react/destructuring-assignment
  );

  const {
    portfolio, role, user, uuid,
  } = portfolioUserMember || {};
  const { title: portfolioTitle, uuid: portfolioUuid } = portfolio || {};

  const intl = useIntl();
  const userMemberUsername = username(user);
  const pageTitle = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.page.title', defaultMessage: 'Enosi - Admin: Portfolio User Member: {userMemberUsername}' }, { userMemberUsername });
  const breadcrumbsAdmin = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.breadcrumbs.admin', defaultMessage: 'Admin' });
  const breadcrumbPortfolios = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.breadcrumbs.portfolios.label', defaultMessage: 'Portfolios' });
  const breadcrumbsPortfolioUserMembers = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.breadcrumbs.portfolio_user_members', defaultMessage: 'Portfolio User Members' });
  const breadcrumbsPortfolioUserMember = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.breadcrumbs.portfolio_user_member', defaultMessage: 'Portfolio User Member: {userMemberUsername}' }, { userMemberUsername });
  const isPortfolioUserMemberActive = isActive(portfolioUserMember.active);
  const retireLabel = intl.formatMessage({
    id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.button.admin_portfolio_user_member_retire.label',
    defaultMessage: 'Retire',
  });

  const editLabel = intl.formatMessage({
    id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.button.admin_portfolio_user_member_edit.label',
    defaultMessage: 'Edit',
  });
  const retireTarget = `/admin/portfolio-user-members/${uuid}/retire`;
  const editTarget = `/admin/portfolio-user-members/${uuid}/edit`;

  const toolbarButtons = [
    { label: editLabel, target: editTarget },
    { label: retireLabel, target: retireTarget, className: 'btn btn-danger' },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: breadcrumbsAdmin, path: '/admin' },
          { name: breadcrumbPortfolios, path: '/admin/portfolios' },
          { name: `${portfolioTitle}`, path: `/admin/portfolios/${portfolioUuid}` },
          { name: breadcrumbsPortfolioUserMembers },
          { name: breadcrumbsPortfolioUserMember },
        ]}
      />
      <div className="d-flex justify-content-between align-items-start mb-4">
        <h1>{userMemberUsername}</h1>
        <div className="flex-shrink-0">
          <AdminPortfolioButtonsToolbar
            isActive={isPortfolioUserMemberActive}
            toolbarButtons={toolbarButtons}
          />
        </div>
      </div>

      <Row className="clear-both">
        <Col xs={12} sm={6} md={4}>
          <UserMemberDetailsCard portfolioUserMember={portfolioUserMember} />
        </Col>

        <Col xs={12} sm={6} md={8}>
          <UserMemberPortfolioCard portfolio={portfolio} />
          <UserMemberUserCard user={user} role={role} />
        </Col>
      </Row>
    </>
  );
}

AdminPortfolioUserMemberShow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  portfolioUserMember: PropTypes.object,
};
AdminPortfolioUserMemberShow.defaultProps = {
  portfolioUserMember: null,
};

export default AdminPortfolioUserMemberShow;
