import { useIntl } from 'react-intl';

const getPropertyMemberLabel = (title, address) => {
  const intl = useIntl();

  if (!title && address) {
    return intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_retire.address_only_label', defaultMessage: '{address}' }, { address });
  }

  if (title && !address) {
    return intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_retire.title_only_label', defaultMessage: '{title}' }, { title });
  }

  if (!title && !address) {
    return intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_retire.default_property_member_label', defaultMessage: 'this property member' });
  }

  return `${title} (${address})`;
};

export default getPropertyMemberLabel;
