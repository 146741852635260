import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  Card, CardHeader, Table,
} from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import UUID from 'src/components/UUID';
import username from 'src/util/decorators/username';
import getTimeRangeMsg from 'src/util/timeRange';

/**
 * @param {object} props
 * @param {object} props.user portfolio user member's user details
 * @param {object} props.user.active portfolio user member's user active timerange
 * @param {number} props.user.active.start portfolio user member's user active start timestamp
 * @param {number} props.user.active.finish portfolio user member's user active finish timestamp
 * @param {string} props.user.email portfolio user member's user's email
 * @param {string} props.user.externalIdentifier portfolio user member's user's external Identifier
 * @param {string} props.user.familyName portfolio user member's user's family name
 * @param {string} props.user.givenName portfolio user member's user's given name
 * @param {string} props.user.uuid portfolio user member's user's uuid
 * @returns {React.ReactElement | null} - UserMemberUserCard component
 */
function UserMemberUserCard(props) {
  if (!props) return null;

  const { user } = props || {};

  const {
    active, email,
    externalIdentifier, uuid,
  } = user || {};

  const { start, finish } = active || {};
  const intl = useIntl();

  return (
    <Card className="mb-4">
      <CardHeader>
        {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user.card.card_header.user.label', defaultMessage: 'User member' })}
      </CardHeader>
      <Table responsive>
        <tbody>
          <tr>
            <th scope="col">
              {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user.card.card_body.head.username.label', defaultMessage: 'Username' })}
            </th>
            <th scope="col">
              <abbr title={intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user_card.card_body.head.id.abbr.title', defaultMessage: 'Identifier' })}>
                {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user.card.card_body.head.id.label', defaultMessage: 'ID' })}
              </abbr>
            </th>
            <th scope="col">
              {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user.card.card_body.head.email.label', defaultMessage: 'Email' })}
            </th>
            <th scope="col">
              <abbr title={intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user_card.card_body.head.external_identifier.abbr.title', defaultMessage: 'External identifier' })}>
                {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user.card.card_body.head.external_identifier.label', defaultMessage: 'Ext ID' })}
              </abbr>
            </th>
            <th scope="col">
              {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_show.user.card.card_body.head.active.label', defaultMessage: 'Active' })}
            </th>
          </tr>
          <tr>
            <th scope="row" colSpan={2}>
              <Link to={`/admin/users/${uuid}`}>
                <span style={{ fontWeight: 'bold' }}>{username(user)}</span>
              </Link>
              <br />
              <UUID uuid={uuid} />
            </th>
            <td>{email}</td>
            <td>{externalIdentifier}</td>
            <td aria-label="badge active">
              <BadgeActive start={start} finish={finish} />
              <br />
              {getTimeRangeMsg(active, intl)}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}

UserMemberUserCard.propTypes = {
  user: PropTypes.shape({
    active: PropTypes.shape({
      start: PropTypes.number,
      finish: PropTypes.number,
    }),
    email: PropTypes.string,
    externalIdentifier: PropTypes.string,
    familyName: PropTypes.string,
    givenName: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
};

export default UserMemberUserCard;
