/**
 * Generates a display name for a user based on their email, given name, and family name.
 * @param {object} user - The user object containing email, givenName, and familyName properties.
 * @returns {string} The display name for the user.
 */
const username = (user) => {
  if (!user) { return 'System'; }

  const { email, givenName, familyName } = user;
  const name = [givenName, familyName].filter(Boolean).join(' ');

  return (name === '' ? email : name);
};

export default username;
