import { graphql, useMutation } from 'react-relay';

const mutation = graphql`
  mutation PortfolioAddUserByEmailMutation($uuid: ID!, $input: PortfolioAddUserByEmailInput!) {
    portfolioAddUserByEmail(uuid: $uuid, input: $input) {
      id
      uuid
    }
  }
`;

/**
 * Hook for adding user by email to a portfolio.
 * Using `useMutation` hook as recommended by [Relay docs](https://relay.dev/docs/api-reference/use-mutation/)
 * as an improvement over calling `commitMutation` directly.
 * @returns {Function} A function to trigger the portfolioAddUserByEmail mutation.
 */
function usePortfolioAddUserByEmailMutation() {
  const [commit] = useMutation(mutation);

  const portfolioAddUserByEmail = (uuid, input, onCompleted, onError) => commit({
    variables: { uuid, input },
    onCompleted,
    onError,
  });
  return portfolioAddUserByEmail;
}

export default usePortfolioAddUserByEmailMutation;
