/**
 * @generated SignedSource<<76f36bc28ee679e2fc623dc0656f1fb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AdminPortfolioPropertyMemberRetire_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PortfolioPropertyMember",
        "kind": "LinkedField",
        "name": "portfolioPropertyMember",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdminPortfolioPropertyMemberRetire_portfolioPropertyMember"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_AdminPortfolioPropertyMemberRetire_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PortfolioPropertyMember",
        "kind": "LinkedField",
        "name": "portfolioPropertyMember",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalIdentifier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Portfolio",
            "kind": "LinkedField",
            "name": "portfolio",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "line1",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "line2",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "city",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "postcode",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1ff842f8b9280639015757479938cb76",
    "id": null,
    "metadata": {},
    "name": "routes_AdminPortfolioPropertyMemberRetire_Query",
    "operationKind": "query",
    "text": "query routes_AdminPortfolioPropertyMemberRetire_Query(\n  $uuid: ID!\n) {\n  portfolioPropertyMember(uuid: $uuid) {\n    ...AdminPortfolioPropertyMemberRetire_portfolioPropertyMember\n    id\n  }\n}\n\nfragment AdminPortfolioPropertyMemberRetire_portfolioPropertyMember on PortfolioPropertyMember {\n  uuid\n  externalIdentifier\n  portfolio {\n    uuid\n    title\n    id\n  }\n  property {\n    title\n    address {\n      line1\n      line2\n      city\n      state\n      country\n      postcode\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

node.hash = "e60ee0b6e8ace2b8ab4b470f4ddbfb09";

module.exports = node;
