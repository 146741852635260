import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import usePortfolioUserMemberUpdateRoleMutation from 'src/mutations/PortfolioUserMemberUpdateRoleMutation';
import FlashesStore from 'src/stores/FlashesStore';
import { PORTFOLIO_USER_ROLE_OWNER } from 'src/util/constants';
import username from 'src/util/decorators/username';

import Form from './Form';

const AdminPortfolioUserMemberEditFragment = graphql`
fragment AdminPortfolioUserMemberEdit_portfolioUserMember on PortfolioUserMember {
  uuid
  portfolio {
    uuid
    title
    userMembers {
      edges {
        node {
          uuid
          role
        }
      }
    }
  }
  role
  user {
    email
    givenName
    familyName
  }
}
`;

/**
 * @param {object} props
 *@returns {React.ReactComponentElement} - AdminPortfolioUserMemberEdit component
 */
function AdminPortfolioUserMemberEdit(props) {
  if (!props || Object.keys(props).length === 0) {
    return <Loading />;
  }

  let { portfolioUserMember } = props;
  portfolioUserMember = useFragment(
    AdminPortfolioUserMemberEditFragment,
    portfolioUserMember,
  );
  const [processing, setProcessing] = useState(false);
  const { router } = props;

  const {
    user, uuid: userMemberUuid, portfolio, role,
  } = portfolioUserMember || {};
  const { title: portfolioTitle, uuid: portfolioUuid, userMembers } = portfolio || {};
  const { email } = user || {};
  let userMemberUsername = username(user);
  userMemberUsername = (email && userMemberUsername !== email) ? `${userMemberUsername} (${email})` : userMemberUsername;

  const userMembersRoles = userMembers?.edges?.map((edge) => edge?.node?.role);
  const portfolioHasOwner = userMembersRoles?.includes(PORTFOLIO_USER_ROLE_OWNER);

  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_edit.page.title', defaultMessage: 'Enosi Admin - Edit portfolio user member: {userMemberUsername}' }, { userMemberUsername });
  const breadcrumbAdmin = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_edit.breadcrumbs.admin.label', defaultMessage: 'Admin' });
  const breadcrumbPortfolios = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_edit.breadcrumbs.portfolios.label', defaultMessage: 'Portfolios' });
  const breadcrumbPortfolioUserMembers = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_edit.breadcrumbs.portfolio_user_members.label', defaultMessage: 'Portfolio user members' });
  const breadcrumbsPortfolioUserMemberEdit = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_edit.breadcrumbs.portfolio_property_member', defaultMessage: "Edit portfolio user member's role" });

  const formSuccessHandler = (response, errors) => {
    setProcessing(false);

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: {
          errors: [error],
        },
      }));
      return;
    }

    const { uuid: editedUserId } = response.portfolioUserMemberUpdateRole;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      <span>
        {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_edit.form.response_message.success', defaultMessage: 'Successfully edited portfolio user member {uuid}.' }, {
          uuid: editedUserId,
        })}
      </span>,
    );
    router.push(`/admin/portfolio-user-members/${editedUserId}`);
  };

  const formFailureHandler = (error) => {
    setProcessing(false);
    FlashesStore.flash(FlashesStore.ERROR, error);
  };
  const portfolioUserMemberUpdateRole = usePortfolioUserMemberUpdateRoleMutation();

  const handleEdit = (input) => {
    setProcessing(true);
    portfolioUserMemberUpdateRole(
      userMemberUuid,
      input,
      formSuccessHandler,
      formFailureHandler,
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: breadcrumbAdmin, path: '/admin' },
          { name: breadcrumbPortfolios, path: '/admin/portfolios' },
          { name: `${portfolioTitle}`, path: `/admin/portfolios/${portfolioUuid}` },
          { name: breadcrumbPortfolioUserMembers },
          { name: userMemberUsername, path: `/admin/portfolio-user-members/${userMemberUuid}` },
          { name: breadcrumbsPortfolioUserMemberEdit },
        ]}
      />
      <Form
        handleEdit={handleEdit}
        portfolioHasOwner={portfolioHasOwner}
        processing={processing}
        role={role}
      />
    </>
  );
}

export default AdminPortfolioUserMemberEdit;

AdminPortfolioUserMemberEdit.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  portfolioUserMember: PropTypes.object.isRequired,
};
