/**
 * @generated SignedSource<<0dc8d2558d6508fa10ef7a8047733a9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AdminPortfolioUserMemberCreate_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Portfolio",
        "kind": "LinkedField",
        "name": "portfolio",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdminPortfolioUserMemberCreate_portfolio"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_AdminPortfolioUserMemberCreate_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Portfolio",
        "kind": "LinkedField",
        "name": "portfolio",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeRange",
            "kind": "LinkedField",
            "name": "active",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "start",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "finish",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PortfolioUserMemberConnection",
            "kind": "LinkedField",
            "name": "userMembers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PortfolioUserMemberEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfolioUserMember",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "role",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "348eb63a508e861445a58149108237cc",
    "id": null,
    "metadata": {},
    "name": "routes_AdminPortfolioUserMemberCreate_Query",
    "operationKind": "query",
    "text": "query routes_AdminPortfolioUserMemberCreate_Query(\n  $uuid: ID!\n) {\n  portfolio(uuid: $uuid) {\n    ...AdminPortfolioUserMemberCreate_portfolio\n    id\n  }\n}\n\nfragment AdminPortfolioUserMemberCreate_portfolio on Portfolio {\n  uuid\n  title\n  active {\n    start\n    finish\n  }\n  userMembers {\n    edges {\n      node {\n        uuid\n        role\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "1363931a51b13e1004bd0b7405e48cbe";

module.exports = node;
