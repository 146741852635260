import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import {
  ALL_PORTFOLIO_USERS, PORTFOLIO_USER_ROLES,
  PORTFOLIO_USER_ROLE_ADMIN, PORTFOLIO_USER_ROLE_OWNER,
  PORTFOLIO_USER_ROLE_STANDARD, PORTFOLIO_USER_ROLE_VIEWER,
} from 'src/util/constants';
import { getPortfolioUserRoleLabel } from 'src/util/i18n/helpers';

/**
 * @param {object} props
 * @param {Function} props.filterUsersByRoleTypeFunc - Function to filter users by role type
 * @param {Array< PORTFOLIO_USER_ROLE_OWNER
 * | PORTFOLIO_USER_ROLE_ADMIN | PORTFOLIO_USER_ROLE_STANDARD
 * | PORTFOLIO_USER_ROLE_VIEWER>} props.roles - An ordered list of role keys to filter by.
 * @param {ALL_PORTFOLIO_USERS | PORTFOLIO_USER_ROLE_OWNER |
 * PORTFOLIO_USER_ROLE_ADMIN | PORTFOLIO_USER_ROLE_STANDARD |
 * PORTFOLIO_USER_ROLE_VIEWER} props.roleType - Selected user role or the default
 * All portfolio users.
 * @returns {React.ReactComponentElement} - PortfolioUserRoleControl component
 */
function PortfolioUserMemberListRoleControl(props) {
  const { filterUsersByRoleTypeFunc, roles, roleType } = props;

  const [userRoleDropDownOpen, setuserRoleDropDownOpen] = useState(false);

  const intl = useIntl();

  const toggle = () => {
    setuserRoleDropDownOpen(!userRoleDropDownOpen);
  };

  return (
    <ButtonGroup className="me-2 mb-2" style={{ listStyleType: 'none' }}>
      <Dropdown
        nav
        inNavbar
        isOpen={userRoleDropDownOpen}
        toggle={toggle}
      >
        <Button size="sm" className="btn btn-darken" style={{ borderRadius: '5px' }} onClick={toggle}>
          {roleType === ALL_PORTFOLIO_USERS ? <FormattedMessage id="portfolio.portfolio_show.portfolio_user_member_list.portfolio_user_role_control.role.label" defaultMessage="Role" /> : getPortfolioUserRoleLabel(intl, roleType)}
          <DropdownToggle
            nav
            className="dropdown-toggle-split btn btn-darken"
            style={{
              display: 'inline-block',
              paddingLeft: 0,
              borderRadius: '5px',
              borderTopRightRadius: '0',
              borderBottomRightRadius: '0',
            }}
          >
            <FontAwesomeIcon
              icon={faCaretDown}
              size="1x"
              className="ms-2"
            />
          </DropdownToggle>
        </Button>
        <DropdownMenu>
          {roles.sort().map((role) => (
            <DropdownItem
              key={role}
              onClick={() => filterUsersByRoleTypeFunc(role)}
              active={roleType === ALL_PORTFOLIO_USERS ? false : role === roleType}
            >
              {getPortfolioUserRoleLabel(intl, role)}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </ButtonGroup>
  );
}

export default PortfolioUserMemberListRoleControl;

PortfolioUserMemberListRoleControl.propTypes = {
  filterUsersByRoleTypeFunc: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.oneOf(PORTFOLIO_USER_ROLES)).isRequired,
  roleType: PropTypes.string.isRequired,
};
