import { graphql, useMutation } from 'react-relay';

const mutation = graphql`
  mutation AddPortfolioMutation($input: AddPortfolioInput!) {
    addPortfolio(input: $input) {
      id
      uuid
    }
  }
`;

/**
 * Hook for adding a portfolio.
 * Using `useMutation` hook as recommended by [Relay docs](https://relay.dev/docs/api-reference/use-mutation/)
 * as an improvement over calling `commitMutation` directly.
 * @returns {Function} A function to trigger the add portfolio mutation
 */
function useAddPortfolioMutation() {
  const [commit] = useMutation(mutation);

  const addPortfolio = (input, onCompleted, onError) => {
    commit({
      variables: { input },
      onCompleted,
      onError,
    });
  };

  return addPortfolio;
}

export default useAddPortfolioMutation;
