import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { createPaginationContainer, graphql } from 'react-relay';

import Address from 'src/components/Address';
import BadgeActive from 'src/components/Badge/BadgeActive';
import LoadMoreButton from 'src/components/LoadMoreButton';
import UUID from 'src/components/UUID';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminPropertyList component
 */
function AdminPropertyList(props) {
  const loadMore = () => {
    const { relay } = props;

    if (!relay.hasMore() || relay.isLoading()) {
      return;
    }

    relay.loadMore(
      50,
      (error) => { console.log(error); },
    );
  };

  if (!props) {
    return <div>Loading...</div>;
  }

  const { relay, viewer } = props;
  const { properties } = viewer;

  const intl = useIntl();
  const copyLoadMore = intl.formatMessage({ id: 'admin.admin_property.admin_property_list.load_more_button.state_load_more.label', defaultMessage: 'Load more...' });
  const copyNoMore = intl.formatMessage({ id: 'admin.admin_property.admin_property_list.load_more_button.state_no_more.label', defaultMessage: 'No more properties to load' });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Enosi Admin - Properties</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: 'Admin', path: '/admin' },
          { name: 'Properties' },
        ]}
      />

      <div className="clearfix">
        <h1 className="float-start">Properties</h1>
      </div>

      <div className="mt-4 mb-4 card">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">Title</th>
                <th scope="col">Address</th>
                <th scope="col">ID</th>
                <th scope="col">
                  <abbr title="External identifier">Ext ID</abbr>
                </th>
                <th scope="col">Community</th>
              </tr>
            </thead>
            <tbody>
              {
                // properties && <pre>{JSON.stringify(properties, null, 2)}</pre>
                properties && properties.edges && properties.edges.map((edge) => {
                  const { node } = edge;
                  const {
                    uuid, title, address, active, externalIdentifier, community,
                  } = node;
                  const { start, finish } = active;
                  const addr = new Address(address);
                  const { uuid: communityId, title: communityTitle } = community;

                  return (
                    <tr key={`admin-property-${uuid}`}>
                      <td>
                        <BadgeActive start={start} finish={finish} />
                        <br />
                        {DateTime.fromSeconds(start).toISO()}
                        {' to '}
                        {finish ? DateTime.fromSeconds(finish).toISO() : 'the future'}
                      </td>
                      <th scope="row" colSpan={2}>
                        <Link to={`/admin/properties/${uuid}`}>
                          {title}
                        </Link>
                        <br />
                        <div>{addr.simpleString()}</div>
                      </th>
                      <td>
                        <UUID uuid={uuid} />
                      </td>
                      <td>
                        {externalIdentifier}
                      </td>
                      <td>
                        <Link to={`/admin/communities/${communityId}`}>
                          {communityTitle}
                        </Link>
                        <br />
                        <UUID uuid={communityId} />
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <div className="card-footer">
          <LoadMoreButton
            copyLoadMore={copyLoadMore}
            copyNoMore={copyNoMore}
            onClick={loadMore}
            hasNext={relay.hasMore()}
          />
        </div>
      </div>
    </>
  );
}

AdminPropertyList.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  viewer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminPropertyList.defaultProps = {
  viewer: null,
};

export default createPaginationContainer(
  AdminPropertyList,
  {
    viewer: graphql`
      fragment AdminPropertyList_viewer on Viewer {
        id
        viewerUser {
          email
          givenName
          familyName
        }
        properties(
          first: $count
          after: $cursor
        ) @connection(key: "AdminPropertyList_properties") {
          edges {
            cursor
            node {
              uuid
              title
              externalIdentifier
              address {
                line1
                line2
                city
                state
                postcode
                country
                gps {
                  latitude
                  longitude
                }
              }
              active {
                start
                finish
              }
              community {
                uuid
                title
              }
            }
          }
          count
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer.properties;
    },
    // This is also the default implementation of `getFragmentVariables` if it isn't provided.
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
      };
    },
    query: graphql`
    # Pagination query to be fetched upon calling 'loadMore'.
    # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
    query AdminPropertyList_Pagination_Query(
      $count: Int!
      $cursor: String
    ) {
      viewer {
        ...AdminPropertyList_viewer
      }
    }
  `,
  },
);
