import PropTypes from 'prop-types';
import React from 'react';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';

import AdminPortfolioButtonWithState from './AdminPortfolioButtonWithState';

/**
 * Description
 * @param {object} props
 * @param {boolean} props.isActive whether the portfolio's resource is active or not
 * @param {Array<object>} props.toolbarButtons [{label:'label', target: 'url', className:'btn'}..]
 * @returns {React.ReactComponentElement} - AdminPortfolioButtonsToolbar component
 */
function AdminPortfolioButtonsToolbar({
  isActive, toolbarButtons,
}) {
  const validProps = isActive !== null && toolbarButtons?.length > 0;
  // check the labels and targets are not empty
  const nonEmpty = toolbarButtons?.every(
    ({ label, target }) => label?.trim() && target?.trim(),
  );
  if (!validProps || !nonEmpty) {
    return null;
  }

  return (
    <ButtonToolbar>
      <ButtonGroup className="ms-2">
        {
          toolbarButtons.map((button) => {
            const { label, target, className = 'btn btn-darken' } = button || {};
            return (
              <AdminPortfolioButtonWithState
                key={target}
                className={className}
                isActive={isActive}
                label={label}
                target={target}
              />
            );
          })
        }
      </ButtonGroup>
    </ButtonToolbar>
  );
}

export default AdminPortfolioButtonsToolbar;

AdminPortfolioButtonsToolbar.propTypes = {
  isActive: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  toolbarButtons: PropTypes.arrayOf(PropTypes.object).isRequired,
};
