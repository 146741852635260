import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
/**
 *
 * @param {object} props
 * @param {boolean} props.isActive
 * @param {string}  props.className
 * @param {string} props.label
 * @param {string} props.target
 * @returns {React.ReactComponentElement} - AdminPortfolioButtonWithState component
 */
function AdminPortfolioButtonWithState({
  isActive, className, label, target,
}) {
  return isActive ? (
    <Link
      to={target}
      className={className}
    >
      {label}
    </Link>
  ) : (
    <Button
      disabled
      className={className}
    >
      {label}
    </Button>
  );
}

export default AdminPortfolioButtonWithState;

AdminPortfolioButtonWithState.propTypes = {
  isActive: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
};
