import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import Address from 'src/components/Address';
import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import usePortfolioRetirePropertyMutation from 'src/mutations/PortfolioRetireProperty';
import FlashesStore from 'src/stores/FlashesStore';

import Form from './Form';
import getPropertyMemberLabel from './helpers';

const AdminPortfolioPropertyMemberRetireFragment = graphql`
fragment AdminPortfolioPropertyMemberRetire_portfolioPropertyMember on PortfolioPropertyMember {
  uuid
  externalIdentifier
  portfolio {
    uuid
    title
  }
  property {
    title
    address {
      line1
      line2
      city
      state
      country
      postcode
    }
  }
}
`;

/**
 * @param {object} props
 *@returns {React.ReactComponentElement} - AdminRetirePortfolioPropertyMember component
 */
function AdminPortfolioPropertyMemberRetire(props) {
  if (!props || Object.keys(props).length === 0) {
    return <Loading />;
  }

  let { portfolioPropertyMember } = props;
  portfolioPropertyMember = useFragment(
    AdminPortfolioPropertyMemberRetireFragment,
    portfolioPropertyMember,
  );
  const [processing, setProcessing] = useState(false);
  const { router } = props;

  const { property, uuid: propertyMemberUuid, portfolio } = portfolioPropertyMember || {};
  const { title: portfolioTitle, uuid: portfolioUuid } = portfolio || {};
  const { title: propertyMemberTitle, address } = property || {};

  const propertyMemberAddress = new Address(address)?.string();

  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_retire.page.title', defaultMessage: 'Enosi Admin - Retire portfolio property member: {propertyMemberTitle}' }, { propertyMemberTitle });
  const breadcrumbAdmin = intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_retire.breadcrumbs.admin.label', defaultMessage: 'Admin' });
  const breadcrumbPortfolios = intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_retire.breadcrumbs.portfolios.label', defaultMessage: 'Portfolios' });
  const breadcrumbPortfolioPropertyMembers = intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_retire.breadcrumbs.portfolio_property_members.label', defaultMessage: 'Portfolio property members' });
  const breadcrumbsPortfolioPropertyMemberRetire = intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_retire.breadcrumbs.portfolio_property_member', defaultMessage: 'Retire portfolio property member' });

  const propertyTitleAndAddress = getPropertyMemberLabel(
    propertyMemberTitle,
    propertyMemberAddress,
  );

  const formSuccessHandler = (response, errors) => {
    setProcessing(false);

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: {
          errors: [error],
        },
      }));
      return;
    }

    const { uuid: retiredPropertyId } = response.portfolioRetireProperty;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      <span>
        {intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_retire.form.response_message.success', defaultMessage: 'Successfully retired portfolio property member {uuid}.' }, {
          uuid: retiredPropertyId,
        })}
      </span>,
    );
    router.push(`/admin/portfolio-property-members/${retiredPropertyId}`);
  };

  const formFailureHandler = (error) => {
    setProcessing(false);
    FlashesStore.flash(FlashesStore.ERROR, error);
  };
  const portfolioRetireProperty = usePortfolioRetirePropertyMutation();

  const handleSubmit = (event) => {
    event.preventDefault();
    setProcessing(true);
    portfolioRetireProperty(
      portfolioUuid,
      propertyMemberUuid,
      formSuccessHandler,
      formFailureHandler,
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: breadcrumbAdmin, path: '/admin' },
          { name: breadcrumbPortfolios, path: '/admin/portfolios' },
          { name: `${portfolioTitle}`, path: `/admin/portfolios/${portfolioUuid}` },
          { name: breadcrumbPortfolioPropertyMembers },
          { name: propertyTitleAndAddress, path: `/admin/portfolio-property-members/${propertyMemberUuid}` },
          { name: breadcrumbsPortfolioPropertyMemberRetire },
        ]}
      />
      <Form
        handleSubmit={handleSubmit}
        processing={processing}
        propertyTitleAndAddress={propertyTitleAndAddress}
        portfolioTitle={portfolioTitle}
        propertyMemberUuid={propertyMemberUuid}
        portfolioUuid={portfolioUuid}
      />
    </>
  );
}

export default AdminPortfolioPropertyMemberRetire;

AdminPortfolioPropertyMemberRetire.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  portfolioPropertyMember: PropTypes.object.isRequired,
};
