import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  Card, CardHeader, Table,
} from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import UUID from 'src/components/UUID';

/**
 * @param {object} props
 * @param {object} props.portfolioPropertyMember portfolioPropertyMember object
 * @param {number} props.portfolioPropertyMember.uuid portfolioPropertyMember's uuid
 * @param {number} props.portfolioPropertyMember.externalIdentifier
 * portfolioPropertyMember's externalIdentifier
 * @param {object} props.portfolioPropertyMember.active portfolioPropertyMember's active status
 * @param {object} props.portfolioPropertyMember.property portfolioPropertyMember's property
 * @param {number} props.portfolioPropertyMember.createdAt portfolioPropertyMember's created at
 * timestamp
 * @param {number} props.portfolioPropertyMember.updatedAt portfolioPropertyMember's updated at
 * timestamp
 * @returns {React.ReactElement} - PropertyMemberDetailsCard element
 */
function PropertyMemberDetailsCard(props) {
  const { portfolioPropertyMember } = props || {};
  const {
    uuid, externalIdentifier, active, createdAt, updatedAt,
  } = portfolioPropertyMember || {};
  const { start, finish } = active || {};
  const intl = useIntl();

  return (
    <Card>
      <CardHeader>
        {intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_member_details.table.card_header.property_member_details.label', defaultMessage: 'Property member details' })}
      </CardHeader>
      <Table responsive>
        <tbody>
          <tr>
            <th scope="row" className="text-right">
              {intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_member_details_card.card_body.head.active_status.label', defaultMessage: 'Active status' })}
            </th>
            <td>
              <BadgeActive start={start} finish={finish} />
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              <abbr title={intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_member_details_card.card_body.head.id.abbr.title', defaultMessage: 'Identifier' })}>
                {intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_member_details_card.card_body.head.id.label', defaultMessage: 'ID' })}
              </abbr>
            </th>
            <td>
              <UUID uuid={uuid} />
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              <abbr title={intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_member_details_card.card_body.head.external_identifier.abbr.title', defaultMessage: 'External identifier' })}>
                {intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_member_details_card.card_body.head.external_identifier.label', defaultMessage: 'Ext ID' })}
              </abbr>
            </th>
            <td>{externalIdentifier}</td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              {intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_member_details_card.card_body.head.start.label', defaultMessage: 'Start' })}
            </th>
            <td>
              {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              {intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_member_details_card.card_body.head.finish.label', defaultMessage: 'Finish' })}
            </th>
            <td>
              {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              {intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_member_details_card.card_body.head.created_at.label', defaultMessage: 'Created at' })}
            </th>
            <td>
              {createdAt
            && DateTime.fromSeconds(createdAt).toLocaleString(DateTime.DATETIME_FULL)}
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-right">
              {intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.property_member_details_card.card_body.head.updated_at.label', defaultMessage: 'Updated at' })}
            </th>
            <td>
              {updatedAt
            && DateTime.fromSeconds(updatedAt).toLocaleString(DateTime.DATETIME_FULL)}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}

PropertyMemberDetailsCard.propTypes = {
  portfolioPropertyMember: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    externalIdentifier: PropTypes.string.isRequired,
    property: PropTypes.shape({
      title: PropTypes.string.isRequired,
      address: PropTypes.shape({
        line1: PropTypes.string.isRequired,
        line2: PropTypes.string,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
      }).isRequired,
    }),
    active: PropTypes.shape({
      start: PropTypes.number.isRequired,
      finish: PropTypes.number,
    }),
    createdAt: PropTypes.number.isRequired,
    updatedAt: PropTypes.number.isRequired,
  }).isRequired,
};

export default PropertyMemberDetailsCard;
