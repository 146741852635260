import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import usePortfolioRetireMutation from 'src/mutations/PortfolioRetireMutation';
import FlashesStore from 'src/stores/FlashesStore';

import Form from './Form';

const AdminPortfolioRetireFragment = graphql`
  fragment AdminPortfolioRetire_portfolio on Portfolio {
    uuid
    title
  }
`;

/**
 * @param {object} props
 *@returns {React.ReactComponentElement} - AdminPortfolioRetire component
 */
function AdminPortfolioRetire(props) {
  if (!props || Object.keys(props).length === 0) {
    return <Loading />;
  }

  let { portfolio } = props;
  portfolio = useFragment(AdminPortfolioRetireFragment, portfolio);
  const { title, uuid } = portfolio || {};
  const [processing, setProcessing] = useState(false);
  const { router } = props;

  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_retire.page.title', defaultMessage: 'Enosi Admin - Retire portfolio: {portfolioTitle}' }, { portfolioTitle: title });
  const breadcrumbAdmin = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_retire.breadcrumbs.admin.label', defaultMessage: 'Admin' });
  const breadcrumbPortfolios = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_retire.breadcrumbs.portfolios.label', defaultMessage: 'Portfolios' });

  const formSuccessHandler = (response, errors) => {
    setProcessing(false);

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: {
          errors: [error],
        },
      }));
      return;
    }

    const { uuid: retiredPortfolioId } = response.retirePortfolio;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      <span>
        <FormattedMessage
          id="admin.admin_portfolio.admin_portfolio_retire.form.response_message.success"
          defaultMessage="Successfully retired <strong>{title}</strong> {uuid}."
          values={{
            strong: (chunks) => <strong>{chunks}</strong>,
            title,
            uuid,
          }}
        />
      </span>,
    );
    router.push(`/admin/portfolios/${retiredPortfolioId}`);
  };

  const formFailureHandler = (error) => {
    setProcessing(false);
    FlashesStore.flash(FlashesStore.ERROR, error);
  };
  const retirePortfolio = usePortfolioRetireMutation();

  const handleSubmit = (event) => {
    event.preventDefault();
    setProcessing(true);
    retirePortfolio(
      uuid,
      formSuccessHandler,
      formFailureHandler,
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: breadcrumbAdmin, path: '/admin' },
          { name: breadcrumbPortfolios, path: '/admin/portfolios' },
          { name: title, path: `/admin/portfolios/${uuid}` },
          { name: 'Retire portfolio' },
        ]}
      />
      <Form
        handleSubmit={handleSubmit}
        processing={processing}
        portfolioTitle={title}
        portfolioUuid={uuid}
      />
    </>
  );
}

export default AdminPortfolioRetire;

AdminPortfolioRetire.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  portfolio: PropTypes.object.isRequired,
};
