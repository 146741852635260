import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { Col, Row } from 'reactstrap';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import isActive from 'src/util/isActive';

import PropertyMemberDetailsCard from './PropertyMemberDetailsCard';
import PropertyMemberPortfolioCard from './PropertyMemberPortfolioCard';
import PropertyMemberPropertyCard from './PropertyMemberPropertyCard';
import AdminButtonWithStateToolbar from '../../AdminPortfolio/AdminPortfolioControls/AdminButtonWithStateToolbar';

const AdminPortfolioPropertyMemberShowFragment = graphql`
  fragment AdminPortfolioPropertyMemberShow_portfolioPropertyMember on PortfolioPropertyMember {
    uuid
    externalIdentifier
    active { start finish }
    portfolio {
      uuid
      externalIdentifier
      title
      active {
        start
        finish
      }
    }
    property {
      uuid
      externalIdentifier
      title
      address {
        line1
        line2
        city
        state
        country
        postcode
      }
      active {
        start
        finish
      }
    }
    createdAt
    updatedAt
  }
`;

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminPortfolioPropertyMemberShow component
 */
function AdminPortfolioPropertyMemberShow(props) {
  if (!props) {
    return <Loading />;
  }

  const portfolioPropertyMember = useFragment(
    AdminPortfolioPropertyMemberShowFragment,
    props.portfolioPropertyMember, // eslint-disable-line react/destructuring-assignment
  );
  const { property, portfolio, uuid } = portfolioPropertyMember || {};
  const { title: propertyTitle } = property || {};
  const { title: portfolioTitle, uuid: portfolioUuid } = portfolio || {};

  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.page.title', defaultMessage: 'Enosi Admin - Portfolio Property Member - {propertyTitle}' }, { propertyTitle });
  const breadcrumbsAdmin = intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.breadcrumbs.admin', defaultMessage: 'Admin' });
  const breadcrumbPortfolios = intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.breadcrumbs.portfolios.label', defaultMessage: 'Portfolios' });

  const breadcrumbPortfolioPropertyMembers = intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.breadcrumbs.portfolio_property_members', defaultMessage: 'Portfolio Property Members' });
  const breadcrumbPortfolioPropertyMember = intl.formatMessage({ id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.breadcrumbs.portfolio_property_member', defaultMessage: 'Portfolio Property Member: {propertyTitle}' }, { propertyTitle });
  const isPortfolioPropertyMemberActive = isActive(portfolioPropertyMember.active);

  const toolbarButtonLabel = intl.formatMessage({
    id: 'admin.admin_portfolio_property_member.admin_portfolio_property_member_show.toolbar.button.retire.label',
    defaultMessage: 'Retire',
  });
  const target = `/admin/portfolio-property-members/${uuid}/retire`;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: breadcrumbsAdmin, path: '/admin' },
          { name: breadcrumbPortfolios, path: '/admin/portfolios' },
          { name: `${portfolioTitle}`, path: `/admin/portfolios/${portfolioUuid}` },
          { name: breadcrumbPortfolioPropertyMembers },
          { name: breadcrumbPortfolioPropertyMember },
        ]}
      />

      <div className="d-flex justify-content-between align-items-start mb-4">
        <h1>{propertyTitle}</h1>
        <div className="flex-shrink-0">
          <AdminButtonWithStateToolbar
            isActive={isPortfolioPropertyMemberActive}
            label={toolbarButtonLabel}
            target={target}
          />
        </div>
      </div>

      <Row className="clear-both">
        <Col xs={12} sm={6} md={4}>
          <PropertyMemberDetailsCard portfolioPropertyMember={portfolioPropertyMember} />
        </Col>

        <Col xs={12} sm={6} md={8}>
          <PropertyMemberPortfolioCard portfolio={portfolio} />
          <PropertyMemberPropertyCard property={property} />
        </Col>
      </Row>
    </>
  );
}

AdminPortfolioPropertyMemberShow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  portfolioPropertyMember: PropTypes.object,
};

AdminPortfolioPropertyMemberShow.defaultProps = {
  portfolioPropertyMember: null,
};

export default AdminPortfolioPropertyMemberShow;
