/**
 * @generated SignedSource<<017eb7d5cccfecfa68a1372d3ef74938>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AdminPortfolioUserMemberEdit_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PortfolioUserMember",
        "kind": "LinkedField",
        "name": "portfolioUserMember",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdminPortfolioUserMemberEdit_portfolioUserMember"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_AdminPortfolioUserMemberEdit_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PortfolioUserMember",
        "kind": "LinkedField",
        "name": "portfolioUserMember",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Portfolio",
            "kind": "LinkedField",
            "name": "portfolio",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PortfolioUserMemberConnection",
                "kind": "LinkedField",
                "name": "userMembers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfolioUserMemberEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioUserMember",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "givenName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "familyName",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a59b29223585faa746b0ef1172094b6b",
    "id": null,
    "metadata": {},
    "name": "routes_AdminPortfolioUserMemberEdit_Query",
    "operationKind": "query",
    "text": "query routes_AdminPortfolioUserMemberEdit_Query(\n  $uuid: ID!\n) {\n  portfolioUserMember(uuid: $uuid) {\n    ...AdminPortfolioUserMemberEdit_portfolioUserMember\n    id\n  }\n}\n\nfragment AdminPortfolioUserMemberEdit_portfolioUserMember on PortfolioUserMember {\n  uuid\n  portfolio {\n    uuid\n    title\n    userMembers {\n      edges {\n        node {\n          uuid\n          role\n          id\n        }\n      }\n    }\n    id\n  }\n  role\n  user {\n    email\n    givenName\n    familyName\n    id\n  }\n}\n"
  }
};
})();

node.hash = "b863afe644f2a7e498d1390f0f8b5842";

module.exports = node;
