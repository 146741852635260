import { graphql, useMutation } from 'react-relay';

const mutation = graphql`
  mutation PortfolioUpdateMutation($uuid: ID!,$input: UpdatePortfolioInput!) {
    updatePortfolio(uuid: $uuid, input: $input) {
      uuid
      title
    }
  }
`;

/**
 * Hook for updating a portfolio.
 * Using `useMutation` hook as recommended by [Relay docs](https://relay.dev/docs/api-reference/use-mutation/)
 * as an improvement over calling `commitMutation` directly.
 * @returns {Function} A function to trigger the update portfolio mutation
 */
function usePortfolioUpdateMutation() {
  const [commit] = useMutation(mutation);

  const updatePortfolio = (uuid, input, onCompleted, onError) => {
    commit({
      variables: { uuid, input },
      onCompleted,
      onError,
    });
  };

  return updatePortfolio;
}

export default usePortfolioUpdateMutation;
