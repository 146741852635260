import { graphql, useMutation } from 'react-relay';

const mutation = graphql`
  mutation PortfolioRetireUserMutation($uuid: ID!, $portfolioUserMemberId: ID!) {
    portfolioRetireUser(uuid: $uuid, portfolioUserMemberId: $portfolioUserMemberId) {
      id
      uuid
    }
  }
`;

/**
 * Hook for retiring a user member from a portfolio.
 * Using `useMutation` hook as recommended by [Relay docs](https://relay.dev/docs/api-reference/use-mutation/)
 * as an improvement over calling `commitMutation` directly.
 * @returns {Function} A function to trigger the retire portfolio user member mutation
 */
function usePortfolioRetireUserMutation() {
  const [commit] = useMutation(mutation);

  const portfolioRetireUser = (uuid, portfolioUserMemberId, onCompleted, onError) => {
    commit({
      variables: { uuid, portfolioUserMemberId },
      onCompleted,
      onError,
    });
  };

  return portfolioRetireUser;
}

export default usePortfolioRetireUserMutation;
