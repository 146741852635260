import { DateTime } from 'luxon';

import { TIME_ZONE_UTC } from 'src/util/constants';

/**
 * Check whether a given string has a leading space
 * @param {string} input
 * @returns {boolean} - whether the input has a leading space or not
 */
export function hasLeadingSpace(input) {
  if (!input || typeof input !== 'string') return false;
  return /^\s/.test(input);
}

/**
 * Check whether a given string has a trailing space
 * @param {string} input
 * @returns {boolean} - whether the input has a trailing space or not
 */
export function hasTrailingSpace(input) {
  if (!input || typeof input !== 'string') return false;
  return /\s$/.test(input);
}

export const HAS_LEADING_SPACE = 'HAS_LEADING_SPACE';
export const HAS_TRAILING_SPACE = 'HAS_TRAILING_SPACE';
export const MAXIMUM_LIMIT_EXCEEDED = 'MAXIMUM_LIMIT_EXCEEDED';

/**
 * Check whether a given string has a trailing space
 * @param {string} input
 * @param {number} maximumLength - maximum length for a given input
 * @returns {Array} - indicate the type of error
 */
export function validateExternalIdentifierInput(input, maximumLength = 1024) {
  const errors = [];
  if (!input) return errors;
  if (input.length > maximumLength) errors.push(MAXIMUM_LIMIT_EXCEEDED);
  if (hasLeadingSpace(input)) errors.push(HAS_LEADING_SPACE);
  if (hasTrailingSpace(input)) errors.push(HAS_TRAILING_SPACE);
  return errors;
}

/**
 * Converts an ISO 8601 formatted date string to a DateTime object, using the provided timezone.
 * @param {string} activeStartString - The ISO 8601 formatted date string to convert.
 * @param {string} timezone - The timezone to use when converting the date string.
 * @returns {DateTime|null} - The converted DateTime object, or null if the input
 * string is invalid or empty.
 */
export const activeStartStringToDateTime = (
  activeStartString,
  timezone,
) => {
  if (!activeStartString) {
    return null;
  }
  const zone = activeStartString.match(/T/) ? TIME_ZONE_UTC : timezone;
  const ts = DateTime.fromISO(activeStartString, { zone });
  if (!ts.isValid) {
    return null;
  }

  return ts;
};
