import { graphql, useMutation } from 'react-relay';

const mutation = graphql`
  mutation PortfolioAddPropertyMutation($uuid: ID!, $input: PortfolioAddPropertyInput!) {
    portfolioAddProperty(uuid: $uuid, input: $input,) {
      id
      uuid
    }
  }
`;

/**
 * Hook for adding a property to a portfolio
 * Using `useMutation` hook as recommended by [Relay docs](https://relay.dev/docs/api-reference/use-mutation/)
 * as an improvement over calling `commitMutation` directly.
 * @returns {Function} A function to trigger the add portfolioAddProperty mutation
 */
function usePortfolioAddPropertyMutation() {
  const [commit] = useMutation(mutation);

  const portfolioAddProperty = (uuid, input, onCompleted, onError) => {
    commit({
      variables: { uuid, input },
      onCompleted,
      onError,
    });
  };

  return portfolioAddProperty;
}

export default usePortfolioAddPropertyMutation;
