import { Typeahead as ReactTypeahead } from 'react-bootstrap-typeahead';
import styled from 'styled-components';

/**
 * Styles the Typeahead component from the react-bootstrap-typeahead library, overriding
 * the styles for the close button. The close button is styled with a custom SVG
 * background image with a red fill.
 */
const Typeahead = styled(ReactTypeahead)`
  .btn-close {
    background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23dc3545'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>")
  }
`;

export default Typeahead;
