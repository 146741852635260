import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import Loading from 'src/components/Loading';
import { APIConfig } from 'src/config';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import useAddPortfolioMutation from 'src/mutations/AddPortfolioMutation';
import FlashesStore from 'src/stores/FlashesStore';

import Form from './Form';

/**
 * @param {object} props
 *@returns {React.ReactComponentElement} - AdminPortfolioCreate component
 */
function AdminPortfolioCreate(props) {
  if (!props || Object.keys(props).length === 0) {
    return <Loading />;
  }
  const { router } = props;

  const [processing, setProcessing] = useState(false);

  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.page.title', defaultMessage: 'Enosi Admin - Add Portfolio' });
  const breadcrumbAdmin = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.breadcrumbs.admin.label', defaultMessage: 'Admin' });
  const breadcrumbPortfolios = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.breadcrumbs.portfolios.label', defaultMessage: 'Portfolios' });
  const breadcrumbAddPortfolio = intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.breadcrumbs.add_portfolio.label', defaultMessage: 'Add portfolio' });

  // start: form submit functionalities
  const handleAddPortfolioSuccess = (response, errors) => {
    setProcessing(false);

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: {
          errors: [error],
        },
      }));
      return;
    }

    const { uuid } = response.addPortfolio;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      <span>
        {intl.formatMessage({ id: 'admin.admin_portfolio.admin_portfolio_create.response_message.success', defaultMessage: 'Successfully added portfolio {uuid}.' }, { uuid })}
      </span>,
    );

    router.push(`/admin/portfolios/${uuid}`);
  };

  const handleAddPortfolioFailure = (error) => {
    setProcessing(false);

    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  const addPortfolio = useAddPortfolioMutation();

  const handleAddPortfolio = (input) => {
    setProcessing(true);
    addPortfolio(
      input,
      handleAddPortfolioSuccess,
      handleAddPortfolioFailure,
    );
  };

  // end: form submit functionalities
  const partnerStartTime = DateTime.fromISO(APIConfig().PARTNER_EPOCH);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: breadcrumbAdmin, path: '/admin' },
          { name: breadcrumbPortfolios, path: '/admin/portfolios' },
          { name: breadcrumbAddPortfolio },
        ]}
      />
      <Form
        handleAddPortfolio={handleAddPortfolio}
        processing={processing}
        minStart={partnerStartTime}
        timezone={APIConfig().DEFAULT_TIMEZONE}
      />
    </>
  );
}

export default AdminPortfolioCreate;

AdminPortfolioCreate.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
