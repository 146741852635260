/**
 * @generated SignedSource<<b23378e978db5695bbf85f9c269d5901>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminPortfolioEdit_portfolio",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Portfolio",
  "abstractKey": null
};

node.hash = "e19ec33481dfe338408da618704d7493";

module.exports = node;
