import Link from 'found/Link';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import {
  Button, Card, CardBody, CardFooter, CardHeader,
  Form, FormGroup, Input,
} from 'reactstrap';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import usePortfolioRetireUserMutation from 'src/mutations/PortfolioRetireUser';
import FlashesStore from 'src/stores/FlashesStore';
import username from 'src/util/decorators/username';

const AdminRetirePortfolioUserMemberFragment = graphql`
  fragment  AdminRetirePortfolioUserMember_portfolioUserMember on PortfolioUserMember
  {
    uuid
    portfolio {
      uuid
      title
    }
    user {
      email
      givenName
      familyName
    }
  }
`;

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - AdminRetirePortfolioUserMember component
 */
function AdminRetirePortfolioUserMember(props) {
  if (!props || Object.keys(props)?.length === 0) {
    return <Loading />;
  }
  const { router } = props;

  const portfolioUserMember = useFragment(
    AdminRetirePortfolioUserMemberFragment,
    props.portfolioUserMember, // eslint-disable-line react/destructuring-assignment
  );

  const { portfolio, user, uuid } = portfolioUserMember || {};
  const { email } = user || {};
  const { title, uuid: portfolioId } = portfolio || {};
  let userMemberUsername = username(user);
  userMemberUsername = (email && userMemberUsername !== email) ? `${userMemberUsername} (${email})` : userMemberUsername;

  const [retireValue, setRetireValue] = useState('');
  const [processing, setProcessing] = useState(false);

  const updateRetireValue = (e) => {
    setRetireValue(e?.target?.value);
  };

  // Start: i18n variables
  const intl = useIntl();
  const pageTitle = intl.formatMessage(
    { id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_retire.page.title', defaultMessage: 'Enosi - Admin: Retire Portfolio User Member: {userMemberUsername}' },
    { userMemberUsername },
  );
  const breadcrumbsAdmin = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_retire.breadcrumbs.admin', defaultMessage: 'Admin' });
  const breadcrumbPortfolios = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_retire.breadcrumbs.portfolios.label', defaultMessage: 'Portfolios' });
  const breadcrumbsPortfolioUserMembers = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_retire.breadcrumbs.portfolio_user_members.label', defaultMessage: 'Portfolio user members' });
  const breadcrumbsPortfolioUserMemberRetire = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_retire.breadcrumbs.portfolio_user_member.label', defaultMessage: 'Retire portfolio user member' });

  const formHeader = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_retire.form.header', defaultMessage: 'Retire portfolio user member' });
  const submitLabel = intl.formatMessage({
    id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_retire.form.submit.label',
    defaultMessage: 'Retire {userMemberUsername}',
  }, { userMemberUsername });

  const defaultUserLabel = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_retire.form.default_user_name.label', defaultMessage: 'this user' });
  const defaultPortfolioLabel = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_retire.form.default_portfolio_title.label', defaultMessage: 'the portfolio' });
  const userNameAndEmail = userMemberUsername || defaultUserLabel;
  const portfolioTitle = title || defaultPortfolioLabel;
  const formConfirmationMsg = (
    <FormattedMessage
      id="admin.admin_portfolio_user_member.admin_portfolio_user_member_retire.form.confirmation.message"
      defaultMessage="Are you sure you want to retire {userNameAndEmail} from {portfolioTitle} ?"
      values={{
        portfolioTitle: (
          <Link className="d-inline" to={`/admin/portfolios/${portfolioId}`}>
            {portfolioTitle}
          </Link>
        ),
        userNameAndEmail: (
          <Link to={`/admin/portfolio-user-members/${uuid}`}>
            {userNameAndEmail}
          </Link>
        ),
      }}
    />
  );

  const formDisclaimer = intl.formatMessage({
    id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_retire.form.disclaimer',
    defaultMessage: 'Removing access prevents them for accessing or managing the properties and the users that are members of the porfolio (depending on the user/\'s existing role)',
  });

  const formRetireLabel = intl.formatMessage({
    id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_retire.form.retire.label',
    defaultMessage: 'retire',
  });

  const formTypeRetireMsg = (
    <FormattedMessage
      id="admin.admin_portfolio_user_member.admin_portfolio_user_member_retire.form.type_retire.message"
      defaultMessage="Please type {retire} to confirm"
      values={{
        retire: (
          <strong className="mx-1">{formRetireLabel}</strong>
        ),
      }}
    />

  );

  // End: i18n variables

  // Start: form submit functionalities
  const formSuccessHandler = (response, errors) => {
    setProcessing(false);

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: {
          errors: [error],
        },
      }));
      return;
    }
    const { uuid: retiredUserId } = response.portfolioRetireUser;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      <span>
        {intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_retire.form.response_message.success', defaultMessage: 'Successfully retired portfolio user member {uuid}.' }, {
          uuid: retiredUserId,
        })}
      </span>,
    );
    router.push(`/admin/portfolio-user-members/${retiredUserId}`);
  };

  const formFailureHandler = (error) => {
    setProcessing(false);

    FlashesStore.flash(FlashesStore.ERROR, error);
  };
  const portfolioRetireUser = usePortfolioRetireUserMutation();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (processing) {
      const msgInfo = intl.formatMessage({ id: 'admin.admin_portfolio_user_member.admin_portfolio_user_member_retire.form.state.invalid_still_propcessing', defaultMessage: 'We are still processing your request...' });
      FlashesStore.flash(FlashesStore.INFO, msgInfo);
      return;
    }

    setProcessing(true);

    portfolioRetireUser(
      portfolioId,
      uuid, // portfolioUserMemberId
      formSuccessHandler,
      formFailureHandler,
    );
  };

  // End: form submit functionalities
  const disableSubmitBtn = !(retireValue === formRetireLabel) || processing;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: breadcrumbsAdmin, path: '/admin' },
          { name: breadcrumbPortfolios, path: '/admin/portfolios' },
          { name: `${title}`, path: `/admin/portfolios/${portfolioId}` },
          { name: breadcrumbsPortfolioUserMembers },
          { name: userMemberUsername, path: `/admin/portfolio-user-members/${uuid}` },
          { name: breadcrumbsPortfolioUserMemberRetire },
        ]}
      />
      <Form disabled={processing} onSubmit={handleSubmit}>
        <Card>
          <CardHeader tag="h2">
            {formHeader}
          </CardHeader>
          <CardBody>
            <p className="mb-2">
              {formConfirmationMsg}
            </p>
            <p className=" mb-2">{formDisclaimer}</p>
            <FormGroup className="d-flex align-items-center">
              <p className="d-flex align-items-center mb-0 me-2">{formTypeRetireMsg}</p>
              <Input style={{ width: '15%' }} aria-label="retire" name="retire" type="text" value={retireValue} onChange={(e) => updateRetireValue(e)} />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button test-id="submit" disabled={disableSubmitBtn} className="btn btn-danger">{submitLabel}</Button>
          </CardFooter>
        </Card>
      </Form>
    </>
  );
}

export default AdminRetirePortfolioUserMember;

AdminRetirePortfolioUserMember.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  portfolioUserMember: PropTypes.object.isRequired,
};
