import PropTypes from 'prop-types';
import React from 'react';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';

import AdminPortfolioButtonWithState from './AdminPortfolioButtonWithState';

/**
 * Description
 * @param {object} props
 * @param {boolean} props.isActive whether the portfolio's ressource is active or not
 * @param {string} props.label buttons's label
 * @param {string} props.target url to be used on the Link component
 * @param {string} props.className button's class name
 * @returns {React.ReactComponentElement} - AdminButtonWithStateToolbar component
 */
function AdminButtonWithStateToolbar({
  isActive, label, target, className,
}) {
  if (isActive === null || !label || !target) {
    return null;
  }
  return (
    <ButtonToolbar>
      <ButtonGroup className="ms-2">
        <AdminPortfolioButtonWithState
          isActive={isActive}
          label={label}
          target={target}
          className={className}
        />
      </ButtonGroup>
    </ButtonToolbar>
  );
}

export default AdminButtonWithStateToolbar;
AdminButtonWithStateToolbar.propTypes = {
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  className: PropTypes.string,
};

AdminButtonWithStateToolbar.defaultProps = {
  className: 'btn btn-danger',
};
